@import url("https://fonts.googleapis.com/css2?family=Merriweather+Sans:ital,wght@0,300..800;1,300..800&family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap");

.App {
  padding: 2%;
  max-width: 700px;
  font-family: "Merriweather Sans", sans-serif;
  font-weight: 300;
}

.title {
  font-weight: 500;
  font-size: 5.65em;
  color: #266043;
  text-align: center;
}

.headline {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5em;
  font-weight: 400;
  font-family: "Merriweather Sans";
  width: 100%;
}

.big-text {
  padding-right: 36px;
  text-align: start;
}

.small-text {
  padding-left: 60px;
  font-size: 1em;
  font-weight: 400;
  font-family: "Merriweather Sans";
  width: 100%;
}

.buttons {
  display: flex;
  justify-content: center;
  padding-top: 24px;
}

.buttons > *:not(:last-child) {
  padding-right: 40px;
}

@media only screen and (max-width: 800px) {
  .drawer-button {
    width: 254px;
  }

  .title {
    font-size: 48px;
    text-align: center;
    padding-bottom: 16px;
  }

  .headline {
    flex-direction: column-reverse;
    font-size: 1.2em;
    align-items: center;
  }

  .buttons {
    flex-direction: column;
    align-items: center;
  }

  .buttons > *:not(:last-child) {
    padding-right: 0px;
    padding-bottom: 24px;
  }

  .resume-subsection-title#software-job {
    display: flex;
    flex-direction: column;
  }

  .big-text {
    text-align: center;
    padding: 16px 5% 0 5%;
  }
  .rounded {
    width: 180px;
  }
}

.social-links {
  display: flex;
  justify-content: center;
  align-items: center;
}

.social-links > *:not(:last-child) {
  padding-right: 16px;
}

.rounded {
  border: 1px solid #266043;
  border-radius: 50% !important;
  width: 120px;
}

a {
  color: #266043;
  font-weight: bold;
}

.resume-section {
  padding: 16px;
  font-size: 0.9em;
}

.resume-title {
  font-size: 1.3em;
  font-weight: 500;
  padding-bottom: 16px;
}

.resume-section-title {
  display: flex;
  align-items: center;
  color: #266043;
  font-size: 1.2em;
  font-weight: 500;
  padding-bottom: 16px;
}

.resume-subsection-title {
  display: flex;
  justify-content: space-between;
  font-weight: 600;
}

.resume-icon {
  padding-right: 12px;
}

.drawer-button {
  display: flex;
  align-items: center;
  font-size: 1.1em;
  justify-content: center;
  font-family: "Merriweather Sans";
  font-weight: 600;
  color: #266043;
  padding: 10px 20px;
  border: 1px solid #266043;
  border-radius: 8px;
  cursor: pointer;
  transition: "background-color" 0.3s ease;
}

.drawer-button-chevron {
  padding-left: 12px;
}

.drawer-button:hover {
  color: #ffffff;
  background-color: #266043;
  cursor: pointer;
}
